<template>
  <div>
      
    <v-parallax
      src="../assets/images/slide1.jpg"
      :height="($vuetify.breakpoint.smAndUp)?350:280"
    >
      <v-row
        align="center"
        justify="center"
        class="mt-10"
      >
        <v-col
          class="text-center"
          cols="12"
        >
          <h1 class="text-sm-h4 text-h5 font-weight-thin mb-4">
            Tienda
          </h1>
          <h4 class="subheading text-sm-h5 text-h6">
            No importa la Hora, ni el Lugar cuando se trata de tus pedidos..¡
          </h4>
        </v-col>
      </v-row>
    </v-parallax>

    <!-- tienda -->
    <v-container class="py-15">
      <div class="text-h5 font-weight-bold text-center"><span class="primary--text">{{ total }}</span> Resultados</div>
      <div class="d-flex justify-center align-center my-2">
        <div><v-divider width="100" class="primary"></v-divider></div>
        <div class="text-center mx-5 primary--text">o</div>
        <div><v-divider width="100" class="primary"></v-divider></div>
      </div>

      <v-row class="pt-10">
        <v-col cols="12" md="4" lg="3">
          <v-card
            class="mx-auto"
            max-width="400"
            tile
            :loading="loadSearch"
            v-sticky="{ isSticky: true }"
          >
            <v-card-text>
              <v-text-field
                label="Buscar producto"
                outlined
                hide-details
                v-model="textSearch"
                @input="txtSearch"
              ></v-text-field>
            </v-card-text>

            <v-expansion-panels :value="expand">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Filtrar por categorías
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <v-chip
                      class="ma-2"
                      label
                      @click="selectCat(cat.id)"
                      v-for="(cat, j) in listCats" :key="j"
                      :color="(catSearch==cat.id)?'primary':''"
                    >
                      {{ cat.name }}
                    </v-chip>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  Filtrar por marcas
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <v-chip
                      class="ma-2"
                      label
                      @click="selectBrand(brand.id)"
                      v-for="(brand, k) in listMarks" :key="k"
                      :color="(brandSearch==brand.id)?'primary':''"
                    >
                      {{ brand.name }}
                    </v-chip>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-card-text>
              <v-btn
                block
                color="primary"
                @click="clearFilter"
                class="text-capitalize"
              >
                Limpiar filtro
              </v-btn>
            </v-card-text>
            
          </v-card>
        </v-col>
        <v-col cols="12" md="8" lg="9">
          <v-row>

            <!-- item col -->
            <v-col cols="12" sm="6" md="6" lg="4" v-for="(item, index) in listProducts" :key="index">
              <v-card>
                <!-- image -->
                <v-hover v-slot="{ hover }">
                  <v-card @click="openDetails(item.id)" tile elevation="0">
                    <div class="pa-5" style="position:relative">
                      <v-img
                        :src="assets+item.image"
                        :aspect-ratio="16/12"
                        contain
                      >
                      </v-img>
                      <v-expand-transition>
                        <div
                          v-if="hover"
                          class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal text-h2 white--text"
                          style="height: 100%;"
                        >
                          <v-icon :x-large="$vuetify.breakpoint.smAndUp">mdi-eye</v-icon>
                        </div>
                      </v-expand-transition>
                    </div>
                  </v-card>
                </v-hover>
                <!-- title -->
                <v-card-title>
                  <v-list-item-title class="text-h6">{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle class="grey--text text--darken-1">{{ item.category.name }}</v-list-item-subtitle>
                </v-card-title>
                <!-- actions -->
                <v-card-actions>
                  <span>{{ item.sale_price }}/<small>Bs</small></span>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="#25d366"
                    outlined
                    target="_blank"
                    :href="`https://web.whatsapp.com/send?phone=591${phoneWsp}&text=Hola%2C+quisiera+comprar+este+producto%3A%0D%0A%0D%0A*${item.name}*%0A*Precio:*%20${item.sale_price} Bs.%0D%0A%0D%0AGracias.`"
                  >
                    <v-icon>mdi-whatsapp</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    outlined
                    @click="addToCart({id: item.id, name: item.name, category: item.category.name, price: item.sale_price, img: assets+item.image, quantity: 1})"
                    :loading="item.id == loadId"
                  >
                    <v-icon>mdi-cart-plus</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <!-- end item col -->

          </v-row>
        </v-col>
      </v-row>

      <div class="pt-10 text-center">
        <v-pagination
            v-model="page"
            :length="totalPage"
            :total-visible="5"
            color="primary"
            @input="changePage"
            class="my-5"
        ></v-pagination>
      </div>
    </v-container>
    <!-- end tienda -->

    <v-divider></v-divider>

    <!-- marcas -->
    <carousel-marks></carousel-marks>
    <!-- end marcas -->

    <!-- dialog -->
    <v-dialog
      v-model="detailOpen"
      width="900"
    >
      <v-card>
        <v-fab-transition>
          <v-btn
              absolute
              right
              fab
              small
              style="top: 10px;"
              @click="detailOpen=!detailOpen"
          >
              <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-card min-height="500" class="h-full d-flex align-center card-prod" color="primary" :style="{backgroundImage: `url(${require('../assets/images/table.png')})`}" tile elevation="0">
              <v-card-text>
                <v-img
                  :lazy-src="assets+singleProd.image"
                  :src="assets+singleProd.image"
                  class="mx-auto"
                  contain
                  width="100%"
                  max-height="280px"
                ></v-img>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card class="h-full pa-8 d-flex justify-center flex-column" color="blue-grey darken-4" tile elevation="0">
              <v-card-title class="text-h5">
                {{ singleProd.name }}
              </v-card-title>
              <v-card-subtitle class="primary--text text-h4">
                {{ singleProd.sale_price }}/Bs
              </v-card-subtitle>
              <v-card-text class="font-weight-light" v-if="singleProd.category">
                {{ singleProd.category.name }}
              </v-card-text>
              <v-card-text>
                <v-select
                  v-if="itemsComplements.length>0"
                  :items="itemsComplements"
                  outlined
                  label="Variaciones"
                  v-model="complement"
                ></v-select>
                <div class="d-flex align-center">
                  <v-btn color="primary" class="rounded-r-0" @click="quantity-=1" :disabled="quantity<=1">
                    <v-icon dark>
                      mdi-minus
                    </v-icon>
                  </v-btn>
                  <v-sheet
                    height="36"
                    width="100"
                    class="sheet-cant"
                  >
                    {{ quantity }}
                  </v-sheet>
                  <v-btn color="primary" class="rounded-l-0" @click="quantity+=1" :disabled="quantity>=stock">
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </div>
              </v-card-text>
              <v-card-text class="mt-auto">
                <v-btn
                  :x-large="$vuetify.breakpoint.smAndUp"
                  block
                  color="orange darken-2"
                  @click="addToCart({id: singleProd.id, name: singleProd.name, category: singleProd.category.name, price: singleProd.sale_price, img: assets+singleProd.image, quantity: quantity, complement: complement})"
                  :loading="loadPop"
                >
                  <v-icon class="mr-5">mdi-cart-outline</v-icon>
                  Añadir al carrito
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- end dialog -->

    <!-- overlay -->
    <v-overlay :value="overlay" opacity=".9" z-index="10">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
  const Sticky = {    
    bind(el, binding) {
        setStyle(el, binding.value);
    },
    componentUpdated(el, binding) {
        setStyle(el, binding.value);
    },
  };

  const setStyle = (el, values) => {
    const defaults = {
        isSticky: true,
        offset: 124,
    };
    
    const isSticky = values && values.isSticky !== undefined
        ? values.isSticky
        : defaults.isSticky;

    if (isSticky) {
        const offset = values && values.offset || defaults.offset;

        el.style.top = `${offset}px`;
        el.style.position = 'sticky';
        el.style.position = '-webkit-sticky'; /* Safari */
    } else {
        el.style.cssText = null;
    }
  };

  import CarouselMarks from '../components/CarouselMarks.vue'
  import axios from 'axios'
  export default {
    name: 'Home',
    components: {
      CarouselMarks,
    },
    directives: {
      Sticky,
    },
    data() {
      return {
        overlay: true,
        loadSearch: false,
        loadId: '',
        loadPop: false,
        detailOpen: false,
        quantity: 1,
        stock: 15,
        complement: '',
        // 
        listCats: [],
        listMarks: [],
        expand: 0,
        // main data
        listProducts: [],
        singleProd: {},
        itemsComplements: [],
        page: 1,
        totalPage: null,
        total: 0,
        textSearch: '',
        catSearch: '',
        brandSearch: '',
        // location
        tmpLat: -19.5888419,
        tmpLng: -65.7538486,
        phoneWsp: null
      }
    },
    computed: {
      assets () {
        return this.$store.state.layout.assets;
      },
    },
    mounted() {
      this.getLocation()
      if (this.$route.query.brand) {
        this.brandSearch = this.$route.query.brand
        this.expand = 1
      }
      setTimeout(() => {
        this.getCatsMarks()
        this.getListProducts()
        this.overlay = false
      }, 300);
    },
    methods: {
      /**
       * get location
       */
      getLocation() {
        window.navigator.geolocation.getCurrentPosition(
          (pos)=>{
            const crd = pos.coords;
            this.tmpLat = +crd.latitude
            this.tmpLng = +crd.longitude
          },
          (err)=>{
            console.warn('ERROR(' + err.code + '): ' + err.message)
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          });

        setTimeout(() => {
          this.getPhoneStore()
        }, 300);
      },
      // telefono de la tienda
      async getPhoneStore() {
        await axios.post('get-phonestore', {lat: this.tmpLat, lng: this.tmpLng})
        .then((response) => {
          // console.log(response)
          this.phoneWsp = response.data
        })
        .catch((error) => {
          console.log(error)
        })
      },
      /**
       */
      async getCatsMarks() {
        await axios.post('get-cats-marks', {})
        .then((response) => {
          // console.log(response)
          if (response.data) {
            this.listCats = response.data[0]
            this.listMarks = response.data[1]
          }
        })
        .catch((error) => {
          console.log(error)
        })
      },
      
      /**
       * list products
       */
      txtSearch() {
        this.page = 1
        this.getListProducts()
      },
      selectCat(cat) {
        this.catSearch = cat
        this.brandSearch = ''
        this.page = 1
        this.getListProducts()
      },
      selectBrand(br) {
        this.brandSearch = br
        this.catSearch = ''
        this.page = 1
        this.getListProducts()
      },
      changePage (event) {
        this.page = event;
        this.getListProducts()
      },
      clearFilter() {
        this.catSearch = ''
        this.brandSearch = ''
        this.page = 1
        this.getListProducts()
      },
      async getListProducts() {
        this.loadSearch = true
        // ?page=${this.page}&txt=${this.textSearch}&cat=${this.catSearch}&brand=${this.brandSearch}
        await axios.post(`shop-lists`, {page:this.page, txt:this.textSearch, cat:this.catSearch, brand:this.brandSearch})
        .then((response) => {
          // console.log(response)
          this.listProducts = response.data.data
          this.totalPage = response.data.last_page
          this.total = response.data.total

          this.loadSearch = false
        })
        .catch((error) => {
          console.log(error)
        })
      },

      /**
     * add to cart
     */
    addToCart(item) {
      this.loadId = item.id
      this.loadPop = true
      setTimeout(() => {
        this.$store.dispatch('cart/addProduct', item)
        this.loadId = ''
        this.loadPop = false
        this.detailOpen = false
        this.quantity = 1
        this.complement = ''
      }, 500);
    },
    /**
     * open details
     */
    openDetails(id) {
      var tmpProd = this.listProducts.filter(item => item.id === id)[0]
      
      this.singleProd = tmpProd
      this.itemsComplements = JSON.parse(tmpProd.complements)
      if (this.itemsComplements.length>0) this.complement = this.itemsComplements[0]
      this.detailOpen = true
    }
    },
  }
</script>
